// run `yarn localize` to generate translation files

import type { Language } from 'modules/intl/types';

import en from './en.json';

export type MessageKey = keyof typeof en;

declare global {
    namespace FormatjsIntl {
        interface Message {
            ids: MessageKey;
        }

        interface IntlConfig {
            locale: Language;
        }
    }
}

export { en };
