import merge from 'lodash/merge';

import { currentEnv } from 'constants/index';

import type { EnvConfig } from './config.development';

const envConfig = require(`./config.${currentEnv}.ts`).default;

const defaultConfig = {
    appName: process.env.NEXT_PUBLIC_NAME,
    routes: {
        vips: '/vips',
        events: '/events',
        event: '/event/[id]',
        login: '/login',
        addHall: '/event/[id]/add-hall',
        changePassword: '/user/change-password',
        editHall: '/hall/[id]',
        hallGuests: '/hall/[id]/guests',
        addGuestToHall: '/hall/[id]/add-guest',
        importGuests: '/hall/[id]/import-guests',
        guestDetail: '/guest/[id]',
        addVipGuestsToHall: '/hall/[id]/add-vip-guests',
        vipDetail: '/vip/[id]',
        addVip: '/vips/add',
        rsvpInvitation: '/hall/[id]/rsvp-invitation',
        sendEmail: '/hall/[id]/send-email',
        sendTicket: '/hall/[id]/send-ticket',
        saveTheDate: '/hall/[id]/save-the-date',
        qrGenerator: '/api/qr-generator',
    },
    endpoints: {
        login: '/auth/local',
        events: '/events',
        halls: '/halls',
        eventDetail: '/events/[id]',
        changePassword: '/auth/change-password',
        hallDetail: '/halls/[id]',
        vips: '/vip-guests',
        vipDetail: '/vip-guests/[id]',
        invitationDetailByToken: '/invitation/by-token/[token]',
        invitationConfirmationByToken: '/invitation/by-token/[token]/confirm',
        invitationDeclineByToken: '/invitation/by-token/[token]/decline',
        fileUpload: '/upload',
        uploadedFiles: '/upload/files',
        uploadedFile: '/upload/files/[id]',
        guestDetail: '/guests/[id]',
        guests: '/guests',
        addGuests: '/halls/[id]/guests',
        sendRsvpInvitations: '/invitation/send-rsvp',
        rsvpPreview: '/invitation/previewRsvp',
        setHallAttributes: '/halls/[id]/guest-attributes',
        emailSenders: '/email-senders',
        emailPreview: '/mail/preview',
        sendEmail: '/mail/send',
        ticketEmailPreview: '/invitation/preview-ticket',
        sendTicketEmail: '/invitation/send-ticket',
        emailConfig: '/email',
        saveTheDatePreview: '/interest/save-the-date/preview',
        sendSaveTheDateEmail: '/interest/save-the-date/send',
        interestPromptDetailByToken: '/interest/prompt/[token]',
        interestConfirmationByToken: '/interest/prompt/[token]/confirm',
        interestDeclineByToken: '/interest/prompt/[token]/decline',
        templates: '/templates',
    },
} as const;

type DefaultConfig = typeof defaultConfig;

export type Config = DefaultConfig & EnvConfig;

export const config: Config = merge(defaultConfig, envConfig);
