import type { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
    fontFamily: '"Greycliff-CF", sans-serif',
    globalStyles: _theme => ({
        img: {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        [`[data-radix-popper-content-wrapper]`]: {
            zIndex: '101!important' as unknown as number,
        },
    }),
    defaultRadius: '0.3125rem',
    components: {
        Notification: {
            styles: theme => ({
                root: {
                    minHeight: 60,
                    '&::before': {
                        width: '0.5rem',
                    },
                },
                description: {
                    fontSize: '1rem',
                },
            }),
        },
        InputWrapper: {
            styles: theme => ({ error: { color: theme.colors.error[0] } }),
        },
        ActionIcon: {
            variants: {
                subtle: theme => ({
                    root: {
                        '&:hover': {
                            color: theme.white,
                        },
                    },
                }),
            },
        },
        Container: {
            defaultProps: {
                sizes: {
                    xs: 540,
                    sm: 720,
                    md: 960,
                    lg: 1140,
                    xl: 1320,
                },
            },
        },
        Dropzone: {
            styles: theme => ({
                root: {
                    '&[data-accept]': {
                        color: theme.white,
                    },
                },
            }),
        },
        Button: {
            styles: (_theme, { withLeftIcon }, context) => ({
                root: {
                    height: 'unset',
                    padding: !['primary', 'error'].includes(context.variant as string) ? '0.625rem' : '0.625rem 1.5rem',
                    paddingRight: withLeftIcon && context.variant === 'secondary' ? '1rem' : undefined,
                },
            }),
            variants: {
                primary: theme => ({
                    root: {
                        fontSize: '1rem',
                        lineHeight: '1.55',

                        backgroundColor: theme.colors.accentPrimary[0],
                        color: theme.white,
                        fontWeight: 600,
                        minWidth: 135,

                        '&:hover': {
                            background: theme.colors.accentPrimaryGradient[0],
                        },

                        '&:disabled': {
                            opacity: 0.4,
                            backgroundColor: theme.colors.accentPrimary[0],
                            color: theme.white,
                        },
                    },
                }),
                error: theme => ({
                    root: {
                        fontSize: '.875rem',
                        lineHeight: '1.55',

                        backgroundColor: theme.colors.error[0],
                        color: theme.white,
                        fontWeight: 600,

                        '&:hover': {
                            background: theme.fn.darken(theme.colors.error[0], 0.2),
                        },

                        '&:disabled': {
                            opacity: 0.4,
                            backgroundColor: theme.colors.error[0],
                            color: theme.white,
                        },
                    },
                }),
                secondary: theme => ({
                    root: {
                        backgroundColor: theme.colors.accentSecondary[0],
                        color: theme.white,

                        fontSize: '0.875rem',
                        lineHeight: '1.55',
                        fontWeight: 600,

                        '&:hover': {
                            background: theme.colors.accentSecondaryHover[0],
                        },

                        '&:disabled': {
                            opacity: 0.4,
                            backgroundColor: theme.colors.accentSecondary[0],
                            color: theme.white,
                        },
                    },
                }),
                subtle: (theme, { color }) => ({
                    root: {
                        '&:hover': {
                            backgroundColor: color === 'accentPrimary' ? 'initial' : undefined,
                            color: color === 'accentPrimary' ? '#000B33' : theme.white,
                        },
                    },
                }),
            },
        },
        Loader: {
            defaultProps: theme => ({
                color: theme.white,
            }),
        },
        Modal: {
            defaultProps: {
                radius: 'md',
            },
            styles: {
                title: {
                    fontWeight: 600,
                    fontSize: '1.25rem',
                },
                body: {
                    fontSize: '1rem',
                },
            },
        },
        Select: {
            styles: {
                label: {
                    fontSize: '1rem',
                    fontWeight: 500,
                },
            },
        },
        TextInput: {
            styles: {
                label: {
                    fontSize: '1rem',
                    fontWeight: 500,
                },
            },
        },
    },
    primaryColor: 'accentPrimary',
    colors: {
        accentPrimary: [
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
            '#00147E',
        ],
        accentPrimaryGradient: [
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
            'linear-gradient(135deg, #001376 0%, #000B33 100%)',
        ],
        accentSecondary: [
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
            '#B07F0C',
        ],
        accentSecondaryHover: [
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
            '#8E6400',
        ],
        backgroundsPrimary: [
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
        ],
        foregroundsPrimary: [
            '#212529',
            '#212529',
            '#212529',
            '#212529',
            '#212529',
            '#212529',
            '#212529',
            '#212529',
            '#212529',
            '#212529',
        ],
        error: [
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
            '#F03E3E',
        ],
        border: [
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
            '#D8D8D8',
        ],
    },
};
