export * from './user';
export * from './strapi';
export * from './event';
export * from './hall';
export * from './guest';
export * from './vip-guest';
export * from './guest-status';
export * from './guest-invitation';
export * from './email-sender';
export * from './email-config';
export * from './guest-interest-prompt-data';
export * from './invitation-guest';
export * from './template';

export enum QueryKeyName {
    PAST_EVENTS = 'past_events',
    ACTIVE_EVENTS = 'active_events',
    GUEST_DETAIL = 'guest_detail',
    EVENT_DETAIL = 'event_detail',
    HALL_DETAIL = 'hall_detail',
    VIPS = 'vips',
    VIP_DETAIL = 'vip_detail',
    INVITATION_DETAIL = 'invitation_detail',
    UPLOADED_FILES = 'uploaded_file',
    RSVP_PREVIEW = 'rsvp_preview',
    PLACES = 'places',
    EMAIL_SENDERS = 'email_senders',
    EMAIL_PREVIEW = 'email_preview',
    TICKET_EMAIL_PREVIEW = 'ticket_email_preview',
    EMAIL_CONFIG = 'email_config',
    SAVE_THE_DATE_PREVIEW = 'save_the_date_preview',
    TEMPLATE_OPTIONS = 'template_options',
}

export interface RequestInitWithParams extends RequestInit {
    params: unknown;
}
