import { Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MantineProvider } from '@mantine/core';
import type { AppProps } from 'next/app';
import type { ReactNode } from 'react';
import { SessionProvider } from 'next-auth/react';

import { Notifications } from '@mantine/notifications';

import { QueryClientProvider } from 'modules/api';
import { Intl } from 'modules/intl';
import { theme } from 'styles/theme';
import 'reset.css';

interface ExtendedAppProps extends AppProps {
    children: ReactNode;
}

function App({ Component, pageProps: { session, ...pageProps } }: ExtendedAppProps) {
    return (
        <>
            <SessionProvider session={session}>
                <QueryClientProvider>
                    <Hydrate state={pageProps.dehydratedState}>
                        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
                            <Intl>
                                <Notifications position="bottom-right" />
                                <Component {...pageProps} />
                            </Intl>
                        </MantineProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </Hydrate>
                </QueryClientProvider>
            </SessionProvider>
        </>
    );
}

export default App;
