import { type PropsWithChildren, useState } from 'react';
import { QueryClient, QueryClientProvider as RaQueryClientProvider, QueryCache } from '@tanstack/react-query';

import { config } from 'config';

interface QueryClientProps extends PropsWithChildren {}

export const QueryClientProvider = ({ children }: QueryClientProps) => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                queryCache: new QueryCache({
                    onSuccess: data => {
                        if (typeof data === 'object' && data && 'error' in data) {
                            const error = data.error;

                            if (typeof error === 'object' && error && (error as any).status === 401) {
                                window.location.href = config.routes.login;
                            }
                        }
                    },
                }),
            }),
    );

    return <RaQueryClientProvider client={queryClient}>{children}</RaQueryClientProvider>;
};
