import qs from 'qs';

import { config } from 'config';

import type { RequestInitWithParams } from '../types';

export const getQueryData = async (endpoint: string, options?: RequestInitWithParams) => {
    const queryParams = options?.params ? `?${qs.stringify(options.params)}` : '';

    const response = await fetch(config.api.url + endpoint + queryParams, {
        method: 'GET',
        ...options,
    });

    return await response.json();
};
