import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { StrapiResponseError } from '../types';

export function useApiError(error: unknown) {
    const { formatMessage } = useIntl();

    return useMemo(() => {
        try {
            if (error && error instanceof Error) {
                const err = JSON.parse((error as Error).message) as StrapiResponseError;

                return err.message;
            }

            if (error) {
                return formatMessage({ id: 'error.generalDescription' });
            }

            return null;
        } catch (err) {
            return formatMessage({ id: 'error.generalDescription' });
        }
    }, [error, formatMessage]);
}
