import type { QueryKey, QueryObserverOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getQueryData } from '../services';
import type { RequestInitWithParams } from '../types';
import { useAuthorizationHeaders } from './useAuthorizationHeaders';

export interface AppQueryOptions<
    TQueryKey extends QueryKey = QueryKey,
    TQueryFnData = unknown,
    TOptions extends RequestInitWithParams = RequestInitWithParams,
> extends Pick<
        QueryObserverOptions<TQueryFnData, unknown, TQueryFnData, TQueryFnData, TQueryKey>,
        'enabled' | 'refetchOnWindowFocus'
    > {
    options?: TOptions;
    includeAuthorizationHeader?: boolean;
}

export const useAppQuery = <
    TQueryFnData = unknown,
    TQueryKey extends QueryKey = QueryKey,
    TOptions extends RequestInitWithParams = RequestInitWithParams,
>(
    queryKey: TQueryKey,
    queryEndpoint: string,
    options: AppQueryOptions<TQueryKey, TQueryFnData, TOptions> = {},
) => {
    const { options: fetchOptions, enabled, refetchOnWindowFocus, includeAuthorizationHeader = true } = options;

    const authorizationHeaders = useAuthorizationHeaders();
    const usedAuthorizationHeaders = includeAuthorizationHeader ? authorizationHeaders : ({} as {});

    const defaultOptions: RequestInitWithParams = {
        headers: {
            ...usedAuthorizationHeaders,
            'Content-Type': 'application/json',
        },
        params: fetchOptions?.params,
        ...fetchOptions,
    };

    return useQuery({
        queryKey,
        queryFn: async () => await getQueryData(queryEndpoint, defaultOptions),
        enabled,
        refetchOnWindowFocus,
    });
};
