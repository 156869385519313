import type { UseMutationOptions } from '@tanstack/react-query';

import { generatePath } from 'utils/generatePath';
import { config } from 'config';

import type { Guest, StrapiResponse } from '../types';
import { useAppMutation } from './useAppMutation';

interface Options {
    hallId: number;
    mutationOptions?: UseMutationOptions<StrapiResponse<unknown>, unknown, GuestsData>;
}

interface GuestData extends Partial<Guest['attributes']> {
    hall?: {
        connect: number[];
    };
}

interface GuestsData {
    data: GuestData[];
}

export const useAddGuestsMutation = ({ hallId, mutationOptions }: Options) =>
    useAppMutation<GuestsData, StrapiResponse<unknown>>(
        generatePath(config.endpoints.addGuests, { id: String(hallId) }),
        {
            method: 'post',
        },
        mutationOptions,
    );
