import { config } from 'config';

import type { StrapiResponse } from '../types';

export const getMutationData = async <TPayload, TResponse extends StrapiResponse<unknown>>(
    endpoint: string,
    payload: TPayload,
    options?: RequestInit,
) => {
    const response = await fetch(config.api.url + endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
        ...options,
    });

    const responseData = (await response.json()) as TResponse;

    if (response.ok) {
        return responseData;
    }

    throw new Error(JSON.stringify(responseData.error));
};
