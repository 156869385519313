import { generatePath } from 'utils/generatePath';
import { config } from 'config';

import type { StrapiResponse } from '../types';
import { useAppMutation } from './useAppMutation';

interface Options {
    hallId: number;
}

export const useRemoveGuestsMutation = ({ hallId }: Options) =>
    useAppMutation<{ data: number[] }, StrapiResponse<unknown>>(
        generatePath(config.endpoints.guests, { id: String(hallId) }),
        { method: 'PUT' },
    );
