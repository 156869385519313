import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { getMutationData } from '../services';
import type { StrapiResponse } from '../types';
import { useAuthorizationHeaders } from './useAuthorizationHeaders';

interface Options extends RequestInit {
    includeAuthorizationHeader?: boolean;
}

export const useAppMutation = <TPayload, TResponse extends StrapiResponse<unknown>>(
    queryEndpoint: string,
    options?: Options,
    mutationOptions?: UseMutationOptions<TResponse, unknown, TPayload>,
) => {
    const headerOptions = options?.headers || {};

    const authorizationHeaders = useAuthorizationHeaders();
    const usedAuthorizationHeaders = options?.includeAuthorizationHeader !== false ? authorizationHeaders : ({} as {});

    const defaultOptions: RequestInit = {
        ...options,
        headers: {
            ...usedAuthorizationHeaders,
            'Content-Type': 'application/json',
            ...headerOptions,
        },
    };

    return useMutation({
        mutationFn: async (payload: TPayload) =>
            await getMutationData<TPayload, TResponse>(queryEndpoint, payload, defaultOptions),
        ...mutationOptions,
    });
};
